import React from "react";
import PropTypes from "prop-types";
import styled, { ThemeProvider } from "styled-components";

import GlobalStyle from "../styles/global";
import Theme from "../styles/theme";
import Footer from "./Footer";
import Navbar from "./Navbar";

const SiteRoot = styled.div`
  background: white;
  padding-top: 80px;
`;

const Layout = ({ children }) => (
  <SiteRoot>
    <GlobalStyle />
    <ThemeProvider theme={Theme}>
      <>
        <Navbar />
        {children}
        <Footer />
      </>
    </ThemeProvider>
  </SiteRoot>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
