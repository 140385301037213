import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Container from "./Container";
import Button from "./Button";
import { FooterData, LookingForJob } from "../data/data";

const FooterWrapper = styled.div`
  background: white;
  padding-top: 3rem;
  margin-bottom: 1rem;
  position: relative;
  &:after {
    content: "";
    height: 1rem;
    width: 100%;
    position: absolute;
    bottom: -1rem;
    left: 0;
    background: ${(props) => props.theme.gradients.red};
  }
  section {
    width: 1020px;
    max-width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    .copy {
      width: 100%;
      text-align: center;
      margin-top: 4rem;
      margin-bottom: 2rem;
      a {
        color: ${(props) => props.theme.colors.black};
        font-weight: 500;
        text-decoration: none;
        position: relative;
        transition: all 0.3s ease;
        padding: 0.2rem 0.4rem;
        z-index: 10;
        &:hover {
          color: white;
          &::after {
            height: 100%;
            width: 100%;
            opacity: 1;
          }
        }
        &:after {
          z-index: -1;
          content: "";
          position: absolute;
          height: 0%;
          width: 100%;
          bottom: -1px;
          left: 0;
          opacity: 1;
          transition: all 0.3s ease;
          background: ${(props) => props.theme.gradients.red};
        }
      }
    }
    .left {
      width: 555px;
      max-width: 100%;
      h4 {
        font-size: 4rem;
        line-height: 5.3rem;
        margin-bottom: 3rem;
        @media screen and (max-width: 724px) {
          font-size: 3rem;
          line-height: 4.3rem;
        }
      }
    }
    .right {
      @media screen and (max-width: 724px) {
        ul {
          margin-top: 3rem;
        }
      }
      ul {
        list-style: none;
        padding-left: 1.75rem;
      }
      li {
        margin-bottom: 1rem;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          height: 8px;
          width: 2px;
          background: ${(props) => props.theme.gradients.red};
          top: 0.9rem;
          left: -1.5rem;
          z-index: 5;
        }
        &:after {
          content: "";
          position: absolute;
          height: 8px;
          width: 2px;
          background: ${(props) => props.theme.gradients.red};
          top: 0.9rem;
          left: -1.5rem;
          z-index: 5;
          transform: rotate(90deg);
        }
      }
      a {
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.black};
        letter-spacing: 0.5px;
        position: relative;
        text-decoration: none;
        transition: all 0.3s ease;
        padding: 0.2rem 0.4rem;
        z-index: 10;
        &:hover {
          color: white;
          &::after {
            height: 100%;
            width: 100%;
            opacity: 1;
          }
        }
        &:after {
          z-index: -1;
          content: "";
          position: absolute;
          height: 2px;
          width: 100%;
          bottom: 0px;
          left: 0;
          opacity: 1;
          transition: all 0.3s ease;
          background: ${(props) => props.theme.gradients.red};
        }
      }
    }
  }
`;

const year = new Date().getFullYear();
const { github, linkedIn, twitter } = FooterData.socialLinks;
const jobActive = LookingForJob.mightGetBrokeSoon;

const Footer = () => {
  return (
    <FooterWrapper>
      <Container>
        <div className="left">
          <h4>
            {jobActive
              ? "Say Hello, I am looking for a change!"
              : "Not looking, but inbox open. Say hi or ask me anything - I'll respond ASAP!"}
          </h4>
          <Button
            type={"link"}
            link={"mailto:itsmanoj.contact@gmail.com"}
            text={"Get In Touch"}
          />
        </div>
        <div className="right">
          <ul className="links">
            <li>
              <a target="blank" rel="noopener noreferrer" href={github}>
                Github
              </a>
            </li>
            <li>
              <a target="blank" rel="noopener noreferrer" href={twitter}>
                Twitter
              </a>
            </li>
            <li>
              <a target="blank" rel="noopener noreferrer" href={linkedIn}>
                LinkedIn
              </a>
            </li>
          </ul>
        </div>
        <div className="copy">
          <span>
            &copy; Copyright {year} −{" "}
            <Link to={"/"} style={{ fontWeight: "500" }}>
              Manoj Paramesh
            </Link>
          </span>
        </div>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
