const FooterData = {
  socialLinks: {
    github: "https://github.com/thedarkstrix",
    linkedIn: "https://www.linkedin.com/in/manoj-paramesh",
    twitter: "https://twitter.com/thedarkstrix",
  },
};

const LookingForJob = {
  mightGetBrokeSoon: true,
};

export { FooterData, LookingForJob };
